import { getPaginated } from "@/api/implementation/app/wrapper.js";
import { destroy, get, post, put } from "@/api/implementation/app";
import { convertCamelToSnake } from "@/api/util/keyConverter.js";

export const index = (
  clientId,
  page,
  perPage,
  search,
  sortBy,
  descending,
  params,
) =>
  getPaginated(
    `client/${clientId}/connector`,
    page,
    perPage,
    search,
    sortBy,
    descending,
    params,
  );

export const microservice = (
  microserviceId,
  page,
  perPage,
  search,
  sortBy,
  descending,
  params,
) => {
  return getPaginated("connector", page, perPage, search, sortBy, descending, {
    ...params,
    microserviceId,
  });
};

export const show = (connectorId) => get(`connector/${connectorId}`);

export const create = (clientId, connector) =>
  post(`client/${clientId}/connector`, connector);

export const triggerAction = (connectorId, action) =>
  post(`connector/${connectorId}/manual-action`, {
    action: convertCamelToSnake(action),
  });

export const update = (connector) =>
  put(`connector/${connector.id}`, connector);

export const test = (connector) =>
  get(`microservice/${connector.microservice.id}/test-connectivity`, {
    params: connector,
  });

export const remove = (connectorId) => destroy(`connector/${connectorId}`);
